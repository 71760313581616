import React, { Component } from "react";
import axios from 'axios';
import config from "../config";
import auth0Client from "../Auth/Auth"
import { Col, Row, Spinner, Stack, Form, ButtonGroup, Button, Dropdown } from "react-bootstrap"


import {Helmet} from "react-helmet";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faSortAmountUpAlt, faSortAmountDown, faSort } from '@fortawesome/free-solid-svg-icons'

import SaleStatusBadge from "../components/SaleStatusBadges"
import { store } from 'react-notifications-component';
import Search from '../components/Search'
import ProductTitleBlock from '../components/ProductTitleBlock'
import ChoiceList from '../components/ChoiceList'
import FilterSelector from '../components/FilterSelector'
import Paginate from '../components/Paginate';
import Sort from '../components/Sort'

var qs = require('qs');

library.add(faExternalLinkAlt)
library.add(faSortAmountUpAlt)
library.add(faSortAmountDown)
library.add(faSort)



export default class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSearching: true,
      pageNum: 0,
      offset: 0,
      inventory: [],
      sortDesc: true,
      search: null,
      status: ['Buyable', 'Not Buyable'],
      sowCoverage: true,
      direction:'desc',
      sortBy: 'units_sold'

    };
  }

async componentDidMount(){  
    window.analytics.page('Inventory');
       this.setState({ isLoading: false });

    try {
      this.setState({ isLoading: true });

      await this.getInventory()
        // const inventory = await this.inventory();
        // this.setState({ inventory });
        
      } catch (e) {
        alert(e + " inventory");
      }
      this.setState({ isLoading: false });

      // console.log(this.state)
    }



  handlePageClick = page => {
    this.setState({ pageNum: page }, () => {
      this.getInventory();
      window.scrollTo({top: 0});    
    });
  }; 


async getInventory(){
  this.setState({isSearching: true})
  let inventory = await this.inventory()
  this.setState({ inventory });
  this.setState({isSearching: false})
}

async inventory(page,perPage,filters){

    let result = await axios.get(`${config.api.BASE_URL}/inventory`, {
      params: {page:this.state.pageNum+1, size:perPage, q:this.state.search, status: this.state.status, sort: this.state.sortBy+" "+this.state.direction},
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
      });

     return result.data
}


calculateStockCoverage(totalQuantity,unitsSold,days){
  let result = totalQuantity/(unitsSold/30)
  
  let newValue = (isNaN(result) ? 0 : result);

  return newValue.toFixed(2)

}

handleSortChange =(result) => {

  this.setState({sortBy:result, pageNum: 0}, () => {
    this.getInventory()     
  });
}

handleDirectionChange = (result) => {

  this.setState({direction:result,  pageNum: 0}, () => {
    this.getInventory()     
  });
}



handleSearchSubmit = () => {
  this.setState({ pageNum: 0 }, () => {
    this.getInventory()     
  });
}


handleSearchChange = (value) => {
  this.setState({search: value});
}

checkboxHandle= (result) => {

  this.setState({status: result, pageNum: 0}, this.getInventory)

} 


renderFilters(){
  const options = [
    { value: 'Buyable', label: 'Buyable'},
    { value: 'Not Buyable', label: 'Not Buyable' },
    { value: 'Disabled by Seller', label: 'Disabled by Seller' },
    { value: 'Disabled by Takealot', label: 'Disabled by Takealot' }
  ]

  const sortOptions =[
    {value: 'units_sold', label: 'Units Sold'},
    {value: 'stock_coverage', label: 'Stock Coverage'},
    {value: 'stock_sow', label: 'Stock Coverage incl SOW'},
    {value: 'total_stock', label: 'Units In Stock'},
    {value: 'stock_on_way', label: 'Stock on Way'},
    {value: 'cpt', label: 'CPT Stock'},
    {value: 'jhb', label: 'JHB Stock'},
      {value: 'dbn', label: 'DBN Stock'},
    {value: 'title', label: 'Product Title'}
  ]
  return(

    <Stack direction="horizontal" gap={3} className='mb-2'>
      <Search 
        submitCallback={this.handleSearchSubmit}
        updateCallback={this.handleSearchChange}
        />
      <div className="vr" />
      <FilterSelector>
            <ChoiceList 
              title='Status'
              selected={this.state.status}
              onChange={this.checkboxHandle}
              options={options}
              allowMultiple
            />
      </FilterSelector>
      
      <Sort 
        options={sortOptions}
        selected={this.state.sortBy}
        direction={this.state.direction}
        callback={this.handleSortChange}
        directionCallback={this.handleDirectionChange} 
        />
      
     
    </Stack>
    // </Row> 
  )
}

copyToClipboard(text){
  navigator.clipboard.writeText(text)

  let message = {
    message: 'Copied to Clipboard',
    type: 'success',                         // 'default', 'success', 'info', 'warning'
    container: 'top-right',                // where to position the notifications
    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    dismiss: {
      duration: 1000 
    }} 
  store.addNotification(message)

}

              
renderRows(){
  return(
    <div>
      {this.state.isSearching ?
      <Spinner className="spinner-table" animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner> : ""
      }

    { (this.state.inventory.offers).map(
      (offer, i) => 

      <ProductTitleBlock 
        key={i}
        offer={offer}
        isSearching={this.state.isSearching}
        >
           
        <Col lg={2} xs={2}>
          <Row >
            <Col><SaleStatusBadge>{offer.status}</SaleStatusBadge></Col>
          </Row>
        </Col>

        <Col lg={2} xs={2}>
          <Row className="text-end">
              <Col lg={7} > {offer.totalQuantity} <div className="muted" style={{'font-size': '10px'}}>CPT: {offer.cpt} | JHB:{offer.jhb} | DBN:{offer.dbn}</div></Col>
              <Col lg={5}>{offer.totalStockOnWay}</Col>
             
            </Row>
        </Col>

        <Col lg={2} xs={2}>
          <Row className="text-end">
            <Col lg={6}>{offer.unitsSold}</Col>
            <Col lg={6}>{this.state.sowCoverage? offer.stockCoverageSow : offer.stockCoverage}</Col> 
          </Row>      
        </Col>     
            
      </ProductTitleBlock>

      
      )
    }
  </div>)
}             

renderInventoryTable(){

  return(
  
    <>
     {/* {console.log(this.state)} */}
      <Row className="orders-table-header align-middle"> 
      <div className="fixedWidthImage">
      
        </div> 
                   
        <Col lg={6} xs={6}>
          <Row className="align-items-center align-middle" >
            
            <Col lg={8} sm={12}>  
              <Col>Product</Col>
            </Col>
            
            <Col lg={4} sm={12}>
              <Col lg={1}>Status</Col>
            </Col>
          
          </Row>         
        </Col>
    
      <Col lg={2} xs={2}>
          <Row className="text-end">
              <Col lg={7}>In Stock</Col>
              <Col lg={5}>Stock on way</Col>
             
            </Row>
      </Col>   

      <Col lg={2} xs={2}>
        <Row className="text-end">
          <Col lg={6}>Sold</Col>
          <Col lg={6}>Days</Col> 
        </Row>      
      </Col>    
      </Row>

      {this.renderRows()}
      

    {/* <div>    
       <ReactPaginate
          previousLabel={'‹'}
          nextLabel={'›'}
          breakLabel={'...'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          pageCount={this.state.inventory.pagination.total_pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          //subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName	={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName	={'page-link'}   
        />
        

       </div> */}
    </>
  )

}



render() {
    return (
      <div>
      <Helmet>
        <title>Inventory</title>
      </Helmet>
        <div>
        <Stack direction="horizontal" gap={3} >
            <div ><h1>Inventory</h1></div>
            <div className="ms-auto ">
            <Form.Check 
                        type="switch"
                        id="offerRepricedNotifications"
                        label={<span className="small text-muted">Include Stock On Way in coverage calculation</span> }
                        checked={this.state.sowCoverage}
                        onChange={()=> this.setState(prevState => ({
                          sowCoverage: !prevState.sowCoverage
                        }))}
                        
                    />

</div>
          </Stack>
        {/* {!this.state.isLoading ? this.renderInventory(this.inventory.bind(this)): <div>Loading...</div>} */}
        {!this.state.isLoading ? this.renderFilters() : <div></div>}
        {!this.state.isLoading ? this.renderInventoryTable(): <div>Loading...</div>}
        {!this.state.isLoading ? <Paginate page={this.state.pageNum} onPageChange={this.handlePageClick}></Paginate> : "" }
        </div>
        </div>
    )
}

}