import React, {useState, useCallback,useEffect} from 'react'
import { Modal, Button, Spinner, Row, Col } from 'react-bootstrap';
import {useDropzone} from 'react-dropzone'
import axios from 'axios';
import config from "../../config";
import auth0Client from "../../Auth/Auth"
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile} from '@fortawesome/free-solid-svg-icons';
import repricingSample from '../FileUpload/repricing_upload_example.csv';
import costsSample from '../FileUpload/costs_upload_example.csv';


library.add(faFile)

// import auth0Client from "../Auth/Auth"

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
  }


  const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;


const UploadFile = (props) => {
    
    const [myFiles, setMyFiles] = useState([])
    const [fileUploading, setFileUploading] = useState(false)
    const uploadDetails = {
        repricing: {
            title: 'Repricing File Upload'},

        costs: {
            title: 'Product Costs File Upload',
            }}


    const uploadFiles = async () => {
        setFileUploading(true)
        const formData = new FormData();
        formData.append('file', myFiles[0]);
        formData.append('importType', props.uploadType);
    
        axios.post(`${config.api.BASE_URL}/uploads`, formData, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc':props.currentAccountId }
            
           }).then((response) => { setFileUploading(false) 
            
                                    props.setUploadId(response.data.id)})
        .catch((e) => {
            alert(e);
          
        })
    
    }




    const descriptionText1 = (uploadType) => {
        switch (uploadType) {
            case 'repricing':
                return '432'
                case 'costs':
                    return 'Download a <a href={repricingSample} download="repricing_upload_example">sample CSV template</a> to see an example of the format required'
            default:
                return 'foo';

    }}


    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
      }, [myFiles])

    const {getRootProps, getInputProps, open, acceptedFiles, isDragActive,
        isDragAccept,
        isDragReject}
         = useDropzone({
            // Disable click and keydown behavior
            multiple: false,
            noClick: true,
            noKeyboard: true,
            accept: '.csv',
            onDrop
        
      });
    
      const removeAll = () => {
        setMyFiles([])
      }

      const files = myFiles.map(file => (
        <Row style={{'padding-top': '10px'}} key={file.path} >
          <div style={{width:"10px"}}><FontAwesomeIcon icon={'file'} /></div>
          <Col>{file.path}</Col>
          
          <Col><Button size="sm" variant="outline-danger" onClick={removeAll} disabled={fileUploading}>
                 Remove file
                </Button> </Col>
        </Row>
      ));

    const fileDrop = 
        (       
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <p>Drop file to upload or</p>
                <Button onClick={open}>
                    Add File
                </Button>  
            </Container>
               
        )


    const fileUploadDesc = (uploadType) => {
            if (uploadType === 'costs') {
                return(<p>Download a <a href={costsSample} download="costs_upload_example">sample CSV template</a> to see an example of the format required for adding COGS to your products</p>)
            } else if (uploadType === 'repricing') {
                return(<p>Download a <a href={repricingSample} download="repricing_upload_example">sample CSV template</a> to see an example of the format required</p>)
            }
    }



    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{uploadDetails[props.uploadType].title}</Modal.Title>
            </Modal.Header>
                <Modal.Body>

                    {fileUploadDesc(props.uploadType)}


                    {/*<p>Download a <a href={repricingSample} download="repricing_upload_example">sample CSV template</a> to see an example of the format required</p>*/}
                  
                {myFiles.length > 0 ? <Row><Col>Added File:</Col></Row> :fileDrop}
                <aside>
                    {/* <h4>Files</h4> */}
                    <ul>{files}</ul>
                </aside>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button style={{width:'120px'}} variant="primary" onClick={() => uploadFiles()} disabled={myFiles.length === 0 || fileUploading}   >
                    {!fileUploading? 

                    'Upload File' : 
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  
                    
                }

                </Button>
            </Modal.Footer>

         </>
    )
}

export default UploadFile
