import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from "../../config";
import auth0Client from "../../Auth/Auth";
import {Modal, Button, Placeholder, Table} from 'react-bootstrap';
import NumberFormat from "react-number-format";

const FeesModal = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [details, setDetails] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(show){
            axios.get(
                `${config.api.BASE_URL}/transactions/${props.orderItemId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth0Client.getAccessToken()}`,
                        'shopdeploy-acc': props.sellerAccountId}
                })
                .then((response) => {
                    setDetails(response.data);
                    setIsLoading(false);
            })}}

    , [show]);

    const FormatCurrency = (props) => {

        return(
            <NumberFormat value={parseFloat(Math.abs(props.children)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R'} ></NumberFormat>
        )
    }


    const ModalDetails = () => {
        return(
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{details.order.productTitle} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Fees</h3>

                    <Table striped>
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {details.transactions.map((item, i) =>{
                            return(
                                <tr key={i} >
                                    <td>{item.description}</td>
                                    <td style={{textAlign:'right'}}><FormatCurrency>{item.amount}</FormatCurrency></td>
                                </tr>
                            )
                        })}

                        { (details.order.shippingCost > 0) && <tr><td>Shipping Cost {details.order.quantity > 1 && 'x '+ details.order.quantity}</td><td style={{textAlign:'right'}}><FormatCurrency>{details.order.shippingCost}</FormatCurrency></td></tr>}
                        { (details.order.productCost > 0) && <tr><td>Product Cost {details.order.quantity > 1 && 'x '+ details.order.quantity}</td><td style={{textAlign:'right'}}><FormatCurrency>{details.order.productCost}</FormatCurrency></td></tr>}
                        { (details.order.miscCost > 0) && <tr><td>Misc Cost {details.order.quantity > 1 && 'x '+ details.order.quantity}</td><td style={{textAlign:'right'}}><FormatCurrency>{details.order.miscCost}</FormatCurrency></td></tr>}

                        </tbody>
                    </Table>
                </Modal.Body>
            </>
        )}


    const ModalLoading = () => {
        return(
            <>
                <Modal.Header closeButton>
                    <Placeholder style={{ width: '75%' }} />
                </Modal.Header>
                <Modal.Body>
                    <Placeholder xs={6} />
                    <Placeholder className="w-75" /> <Placeholder style={{ width: '25%' }} />
                </Modal.Body>
        </>)
    }

    return (
        <>
        <a href="#" onClick={(event)=> {event.preventDefault(); handleShow()}}>{props.children}</a>
        <Modal centered show={show} onHide={handleClose} animation={false}>
            {isLoading ? <ModalLoading /> : <ModalDetails/>}


            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
            </>
    );
};

export default FeesModal;