import React, { Component } from "react";
import config from "../../config";
import auth0Client from "../../Auth/Auth"
import { Table, ButtonToolbar, Button, Spinner } from "react-bootstrap";
import dateFns from 'date-fns'
import axios from 'axios';

import ReactPaginate from 'react-paginate';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faArrowDown } from '@fortawesome/free-solid-svg-icons'

library.add(faCircle)


export default class SellerAccount extends Component {
    
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: true,
        accounts: [],
        pageNum: 0,
        offset: 0
      };
    }


    async componentDidMount(){
      window.analytics.page('Admin/Seller Accounts');
      try {
        const accounts = await this.sellerAccounts();
        this.setState({ accounts });
      } catch (e) {
        alert(e + "accounts");
      }
    
                
     this.setState({ isLoading: false });
    }
    

   async getSellerAccounts(){
    this.setState({ isLoading: true });
      let accounts = await this.sellerAccounts()
      this.setState({ accounts });
      this.setState({ isLoading: false });
    }



     sellerAccounts() {
        return axios.get(`${config.api.BASE_URL}/admin/seller_accounts`, {
          params: {page:this.state.pageNum+1},
          headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
        });
        
      }


     renderCircle(value){
       if (value) {
        return <FontAwesomeIcon icon="circle" style={{ color: 'green' }}/>
       } else
       {
        return <FontAwesomeIcon icon="circle" style={{ color: 'red' }}/>
       }
      
     }


     handlePageClick = data => {


      let selected = data.selected;
    //let offset = Math.ceil(selected * 25);
    let offset = Math.ceil(selected * this.props.perPage);
  
      this.setState({ offset: offset, pageNum: data.selected }, () => {
        this.getSellerAccounts();
  
      });

  

  };


    renderAccountsList(accounts){

      return(
        <>
        <Table striped bordered hover size="sm">
        <thead>
        <tr>
          
          <th>Active</th>
          <th>Jobs Active</th>
          <th>Name</th>
          <th>Active Offers</th>
          <th>Revenue</th>
          <th>Units Sold (30d)</th>
          <th>Units sold (avg)</th>

          <th>Returns</th>
          <th>buy Box Notifications Sent</th>
          <th>BB Track New Offers</th>

          <th>onTrial</th>
          <th>trialEnd</th>

          <th>Sign up date</th>
        
        </tr>
        </thead>
      <tbody>
            {(accounts.accounts).map(
        (account, i) =>
          <tr key={i}>
          <td>{ this.renderCircle(!account.accountDisabled)} 

</td>
          <td> {this.renderCircle(account.jobsActive)}</td> 
          <td> {account.name}</td> 
          <td>{account.activeOffers}</td>
          <td>
          {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(account.revenue)}
          </td> 
          <td> {account.unitsSold}</td> 
          <td> {Math.round(account.unitsSold90/3)}</td> 
          <td> {account.returns} <span>({Math.round(((account.returns/account.unitsSold)*100)*10)/10}%)</span></td> 
          
          <td> {account.buyBoxNotificationsSent}</td> 
          <td> {this.renderCircle(account.buyBoxTrackNewOffers)}</td> 
         

          <td> {this.renderCircle(account.onTrial)}</td> 
          <td> {dateFns.format(account.trialEnd,'MMM D') }</td> 


         

          <td> {dateFns.format(account.createdAt,'MMM D, h:mma')}</td>
          {/* <td> {order.attributes.customer}</td> 
          <td> {order.attributes.saleStatus}</td>
          <td> {order.attributes.takealotOrderId}</td> 
          <td> {order.attributes.productTitle}</td>  
          <td> {order.attributes.quantity}</td>  
          <td> {order.attributes.sellingPrice}</td> */}



          </tr>
         )}     
      </tbody>

      </Table>
      <ReactPaginate
      previousLabel={'‹'}
      nextLabel={'›'}
      breakLabel={'...'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      pageCount={this.state.accounts.data.pagination.total_pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.handlePageClick}
      containerClassName={'pagination'}
      //subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName	={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName	={'page-link'}   
    />
      </>
      )
    }
    


    render() {
        return (

          <main role="main" className="col-sm-12 main pt-3">
          {!this.state.isLoading ? this.renderAccountsList(this.state.accounts.data) : <Spinner animation="border" role="status"></Spinner>
          
          
          
          }
          
  
          </main>
          )
    }


}  
