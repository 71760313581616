import React, { useState, useCallback} from 'react'

import { Navbar, Container, Button, NavDropdown, Nav, Image, Offcanvas, Dropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt,} from '@fortawesome/free-solid-svg-icons'
import auth0Client from '../Auth/Auth.js';
import axios from 'axios';
import config from "../config";
import SideNavItems from '../components/SideNavItems'
library.add(faSyncAlt)

const handleLogout = (event) => {
    auth0Client.signOut();
    console.log("sign out")
  }

 async function handleAccountSwitch(accId) {
    await axios.post(`${config.api.BASE_URL}/users/switch_account`, {
      accId: accId,  
    }, {
      headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}` }
    });
    window.location.reload();
  }
  

function listAvailableAccounts(sellerAccounts) {
    return (
      <>
        {sellerAccounts.data.availableAccounts.map((value, index) => {
          return <NavDropdown.Item key={index} 
          onClick={() => {
            handleAccountSwitch(value.id)
            window.analytics.track('Switched Seller Account', {
              name: value.name
            });
  
          }
         
          } >{value.name}</NavDropdown.Item>
        })}
     </>
    )
  }


  const useToggle = (initialState) => {
    const [isToggled, setIsToggled] = useState(initialState);
  
    // put [setIsToggled] into the useCallback's dependencies array
    // this value never changes so the callback is not going to be ever re-created
    const toggle = useCallback(
      () => setIsToggled(state => !state),
      [setIsToggled],
    );
  
    return [isToggled, toggle];
  }  

const NavHeader = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)                                            
    const handleShow = () => setShow(true);

{/* <NavLink className="nav-link" exact to="/" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="chart-bar" /></span>Dashboard</NavLink>   */}
    return(<>

        <header className='header d-flex navbar-dark bg-dark justify-content-between align-items-center ps-1 pe-1'>
        {/*<Navbar className="d-flex" sticky="top" bg="dark" variant="dark" expand="true">*/}
            {/*<Container fluid>*/}
            {auth0Client.isAuthenticated() &&
                <Button className="navbar-toggler collapsed mr-auto mobile-menu" onClick={handleShow}><span className="navbar-toggler-icon"></span></Button>
                }
                <Navbar.Brand className="mr-auto p-2 shdp-logo navbar-b" > <NavLink to="/"><img src="shopdeploy_logo.png" /></NavLink> </Navbar.Brand>

                {/*<div className='d-flex headway-trigger '></div>*/}
                        {auth0Client.isAuthenticated() &&
                            !props.checkingSession &&
<div >
    <Dropdown>
        <Dropdown.Toggle variant="info" bsPrefix="btn" id="dropdown-basic">
            <Image className="navbar-avatar" src={auth0Client.getProfile().picture} roundedCircle />
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {props.sellerAccount.data.signupComplete && listAvailableAccounts(props.sellerAccount)}
            <Dropdown.Divider />
            <Dropdown.Item as={NavLink} exact to="/account">Manage Account</Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>



    {/*<Dropdown align="end" title={<Image className="navbar-avatar" src={auth0Client.getProfile().picture} roundedCircle />} id="basic-nav-dropdown">*/}
    {/*    {props.sellerAccount.data.signupComplete && listAvailableAccounts(props.sellerAccount)}*/}
    {/*    <NavDropdown.Divider />*/}
    {/*    <NavDropdown.Item as={NavLink} exact to="/account">Manage Account</NavDropdown.Item>*/}
    {/*    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>*/}
    {/*</Dropdown>*/}

</div>


                        }

            {/*</Container>*/}
        {/*</Navbar>*/}
        </header>

        <Offcanvas show={show} onHide={handleClose} placement={'start'} >
            <Offcanvas.Header closeButton style={{'border-bottom': '1px solid #c6cbd1'}}>
                <Offcanvas.Title><FontAwesomeIcon icon="sync-alt" /> Shopdeploy</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex">
                <div className='d-flex flex-column'>
                    <SideNavItems className=' ' {...props} handleClose={handleClose}/>
                </div>

       
        {/*<div className="footer">*/}
        
        {/*<Button variant="secondary" onClick={handleClose} >Done</Button></div>*/}
        </Offcanvas.Body>
        </Offcanvas>

</>

)

}


export default NavHeader