import React, { useState, useEffect } from 'react';
import config from "../../config";
import axios from 'axios';
import auth0Client from "../../Auth/Auth";
import { Col, Row, Spinner, Card} from "react-bootstrap";



function MetricsCard(props) {
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
        try {
          const { data: response } = await axios.get(`${config.api.BASE_URL+ props.endpoint}`,{
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.sellerAccount.data.currentAccountId,
        }} );
          setData(response);
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false);
  }

  fetchData();}, []);

  return (
      
    
    <Card className="text-center" >
      <Card.Title className="center-title">{!isLoading ? data[(props.item)] : '~'}</Card.Title>
      <Card.Text><small className="text-muted">{props.label}</small></Card.Text>
      </Card>
    
  );
}

export default MetricsCard