import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import auth0Client from "./Auth/Auth.js";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faBars,
  faTags,
  faWarehouse,
  faDatabase,
  faRobot,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Helmet } from "react-helmet";
import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import config from "./config";
import { inject } from "@vercel/analytics";
import NavHeader from "./components/NavHeader";
import SideNav from "./components/SideNav";
import "./App.css";


import { Spinner } from "react-bootstrap";
import { pageViewed } from "./segment/analytics";

import { Mixpanel } from "./components/Shared/Mixpanel";
import posthog from "posthog-js";

const hConfig = {
  apiKey: "hbp_jTSh5rf5f2F9llbD40dymvbL8VrQrN1zyhEu",
  environment: "production",
  revision: "git SHA/project version",
};
//
Sentry.init({
  dsn: "https://ff92414aa887cc27a6f1db5971d513e6@o230256.ingest.us.sentry.io/4508483613818880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({networkDetailAllowUrls: [/^https:\/\/api\.shopdeploy\.com\/.*/],
      networkRequestHeaders: ['Authorization','Shopdeploy-Acc']
    }),


  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.shopdeploy\.com/],
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


library.add(faWarehouse);
library.add(faSyncAlt);
library.add(faBars);
library.add(faTags);
library.add(faDatabase);
library.add(faRobot);
library.add(faChartLine);

inject();


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkingSession: true,
      sellerAccount: null,
      organisation: { billingRedirect: false },
    };
  }

  async componentDidMount() {
    if (this.props.location.pathname === "/callback") {
      this.setState({ checkingSession: false });
      return;
    }

    try {
      await auth0Client.silentAuth();
      await this.setIdentityData();
      console.log('setting user')
      let user = await this.getUserInfo();
      this.setState({ sellerAccount: user });
      console.log('setting org')
      let org = await this.getOrganisation();
      this.setState({ organisation: org.data });

      this.forceUpdate();
    } catch (err) {
      console.log("Error in silent auth");
      console.log(err);
      auth0Client.signIn();
      if (err.error !== "login_required") console.log(err.error);
    }
    this.setState({ checkingSession: false });
  }

  getUserInfo() {
    return  axios.get(`${config.api.BASE_URL}/users`, {
      headers: { Authorization: `Bearer ${auth0Client.getAccessToken()}` },
    }).then(function (response) {
      // console.log('users fetched', response);
      return response
    })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log('error in getting users',error);
          console.log(error.message)
        });
  }

  getOrganisation() {
    return axios.get(`${config.api.BASE_URL}/organisation`, {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
        "shopdeploy-acc": this.state.sellerAccount.data.currentAccountId,
      },
    });
  }




  async setIdentityData() {
    console.log("Setting identity data");
    const profile = auth0Client.getProfile();
    // console.log(profile);


    Sentry.setUser({ email: profile.name, id: profile.sub });
    posthog.identify(
        profile.sub, // Required. Replace 'distinct_id' with your user's unique identifier
        { email: profile.name,  },  // $set, optional

    );

  }



  setUserAccount(accData) {
    //This function is passed to the callback component
    console.log("Setting seller  account");
    this.setState({ sellerAccount: accData });
    this.setIdentityData();
  }



  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      checkingSession: this.state.checkingSession,
      setUserAccount: this.setUserAccount.bind(this),
      //setSignupState: this.setSignupState.bind(this),
      //signupComplete: this.state.signupComplete,
      sellerAccount: this.state.sellerAccount,
      billingRedirect: true,
      organisation: this.state.organisation,
    };

    return (
        <div>
          <Helmet titleTemplate="%s | Shopdeploy" />
          <ReactNotifications />

          {!this.state.checkingSession ? (
              <>
                <NavHeader {...childProps} />
                <div className="wrapper">
                  <aside>
                    <SideNav {...childProps} />
                  </aside>
                  <main className="main" style={{ marginTop: "4.5rem" }}>
                    <div className="wrapper_inner">
                      <div className="container-fluid">
                        <Routes childProps={childProps} />
                      </div>
                    </div>
                  </main>
                </div>
              </>
          ) : (
              <div style={{ height: "100vh" }}>
                <div className="loader">
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                </div>
              </div>
          )}
        </div>
    );
  }
}

export default withRouter(App);