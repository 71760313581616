import React, {useState, useEffect} from 'react';
import {Card, Col, Row, Button, Modal, Form} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import CustomIntegration from '../components/Integrations/CustomIntegration';
import axios from 'axios';
import auth0Client from "../Auth/Auth";
import config from "../config";


const Integrations = (props) => {
    const [integrations, setIntegrations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const available = [
        {title: 'Shopify', desc: 'Sync orders, stock levels and pricing across all channels.', logo: 'shopify.png'},
        {title: 'Magento', desc: 'Sync Orders, Stock Levels and pricing across all channels.', logo: 'magento.png'},
        {title: 'WooCommerce', desc: 'Sync Orders, Stock Levels and pricing across all channels.', logo: 'woocommerce.png'},
        {title: 'Xero', desc: 'Simplify your accounting. Send your sales directly into Xero', logo: 'woocommerce.png'},
        {title: 'Sage', desc: 'Simplify your accounting. Send your sales directly into Sage', logo: 'woocommerce.png'},
        {title: 'Amazon South Africa', desc: 'Sync products, pricing and stock levels to Seller Central', logo: 'shopify.png'},
        {title: 'Google Sheets', desc: 'Effortlessly create your own dashboards using Shopdeploy data.', logo: 'woocommerce.png'},
        {title: 'Slack', desc: 'Get notifications of new orders, low stock alerts and more.', logo: 'woocommerce.png'},
        {title: 'Telegram', desc: 'Get notifications of new orders, low stock alerts and more.', logo: 'woocommerce.png'},
        {title: 'Account Management', desc: 'Our team of experts will manage all aspects of your Takealot account.', logo: 'woocommerce.png'},
        {title: 'Paid Media Management', desc: "Shopdeploy's Ad Team will manage and optimse your Takealot Ad spend.", logo: 'woocommerce.png'},
    ];


    useEffect (() => {
            axios
                .get(
                    `${config.api.BASE_URL}/integration`,
                    {headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.sellerAccount.data.currentAccountId}}
                )
                .then(function (response) {
                    setIntegrations(response.data);
                    setIsLoading(false)

                })
                .catch(function (error) {
                    console.log(error);
                });
    }
        ,[])


    const addIntegration = (integration) => {
        axios
            .post(
                `${config.api.BASE_URL}/integration`,{integration: integration},
                {headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.sellerAccount.data.currentAccountId}}
            )
            .then(function (response) {
                setIntegrations(response.data);


                setIsLoading(false)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const RequestButton = (props) => {
        return(
            integrations.includes(props.title) ?
                <Button variant="outline-primary" disabled>Requested</Button> :
                <Button variant="outline-primary" onClick={() => {addIntegration(props.title)} } >Request Access</Button>
        )
    }

    const IntegrationGrid = () => {
        return (
            <Row xs={2} md={4} className="g-4">
                {available.map((item, idx) => (
                    <Col>
                        <Card className={'h-100'} >
                            {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text>
                                    {item.desc}
                                </Card.Text>
                                <RequestButton title={item.title}/>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                <CustomIntegration {...props} />

            </Row>
        );
    }

    return (
        <div>
            <Helmet>
                <title>Integrations</title>
            </Helmet>
            <div className="Orders">
                <h1>Integrations & Services</h1>
                {!isLoading ? <IntegrationGrid /> : <p>Loading...</p>}
            </div>
        </div>
    );
};

export default Integrations;