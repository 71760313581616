import React, { Component } from "react";
import loading from "./loading.svg";
import auth0Client from "../Auth/Auth";
import config from "../config";
import axios from "axios";
import * as Sentry from "@sentry/react";

import { pageViewed } from "../segment/analytics";

class Callback extends Component {
    async componentDidMount() {
        auth0Client.handleAuthentication().then(
            (suc) => {

                console.log('getting user info in callback')

                this.getUserInfo().then((res) => {
                    console.log("res in callback auth", res);
                    this.props.setUserAccount(res);
                    this.props.history.replace("/");
                }, (err) => {
                    Sentry.captureException(err);
                    console.log("err in callback setting user data", err);
                    console.log("signing out");

                    auth0Client.signOut();
                })
            },
            (err) => {
                Sentry.captureException(err);
                console.log("err", err);
                auth0Client.signOut();
            }
        );

        //this.setState({sellerAccount: await this.getUserInfo()})
        // this.props.setUserAccount(await this.getUserInfo())
    }

    getUserInfo() {
        return axios.get(`${config.api.BASE_URL}/users`, {
            headers: { Authorization: `Bearer ${auth0Client.getAccessToken()}` },
        }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            Sentry.captureException(error);
        });

        //
        //     .then(function (response) {
        //   console.log('users fetched', response);
        //   return response
        // })
        //     .catch(function (error) {
        //       console.log('error in getting users',error);
        //         console.log(error.message)
        //     });
    }

    render() {
        const style = {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            height: "10vh",
            width: "100vw",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            marginTop: "100px",
        };

        return (
            <div style={style}>
                <img src={loading} alt="loading" />
            </div>
        );
    }
}

export default Callback;

//https://auth0.com/blog/react-tutorial-building-and-securing-your-first-app/#Securing-your-React-App