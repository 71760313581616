import React, { Component } from 'react'
import config from "../config";
import axios from 'axios';
import './Offers.css';
import auth0Client from "../Auth/Auth"
import {Helmet} from "react-helmet";
import {Button, Col, Row, Spinner, Tooltip, OverlayTrigger, Stack } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import StarRatingComponent from 'react-star-rating-component';
import SaleStatusBadge from "../components/SaleStatusBadges"
import FilterSelector from '../components/FilterSelector'
import dateFns from 'date-fns';
import NumberFormat from 'react-number-format';
import Search from '../components/Search'
import ChoiceList from '../components/ChoiceList'
import ProductTitleBlock from '../components/ProductTitleBlock';
import Paginate from '../components/Paginate';
import NoOffersFound from "../components/Repricing/NoOffersFound";
import TableLoading from "../components/TableLoading";
import OfferDesktopTable from "../components/Offers/OfferDesktopTable";

export default class Offers extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            isSearching: true,
            buttonLoading: false,
            pageNum: 0,
            offset: 0,
            offers: [],
            search: null,           
            status: ['Buyable', 'Not Buyable'],
            winning:[],
            buyboxStatus:[],
            repriceAdded:[],
            selectedOffers: []
            
          };
      }

    async componentDidMount(){
        window.analytics.page(`Offers`);
        this.setState({ isLoading: true });
        try {
            await this.getOffers();
            
            //this.setState({ offers });
            
          } catch (e) {
            alert(e + " Offers");
          }
        this.setState({ isLoading: false });
          
    }


    async getOffers(){
      this.setState({isSearching: true})
      let response = await this.offers()
      this.setState({ offers: response.data.offers, pagination: response.data.pagination  });
      this.setState({isSearching: false})
    }


    async offers(page,perPage,filters){
      
        return axios.get(`${config.api.BASE_URL}/offers`, {
            params: {page:this.state.pageNum+1, size:perPage, q:this.state.search, status:this.state.status,  repriceAdded: this.state.repriceAdded, winning:this.state.buyboxStatus },
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
          });
    }

    handleOfferSelect = (offerId) => {

        let selectedOffers = [...this.state.selectedOffers];
        let index = selectedOffers.findIndex(e => e === offerId);
        if(index === -1){
            selectedOffers.push(offerId)
        }else{
            selectedOffers.splice(index, 1);
        }
        this.setState({selectedOffers: selectedOffers});


    }

    handleSelectAllOffers = (event) => {
        if (event.target.checked) {
            let selected = this.state.offers.map(offer => offer.id);
            this.setState({selectedOffers:selected});
        } else {
            this.setState({selectedOffers: []})
        }
    }


   async createDataExport(){
     this.setState({buttonLoading: true})

    let result = await axios.post(`${config.api.BASE_URL}/offers/export`,{}, {
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
      });

      
      let message = {}
      result.status == 200 ? 
      message = {title: 'Export Processing',
      message: result.data.message,
      type: 'success',                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 3000 
      }} : 
      message = {title: 'Failed',
      message: "An error has occured",
      type: 'warning',                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 3000 
      }}

      store.addNotification(message)
      this.setState({buttonLoading: false})
      
    }


  handlePageClick = page => {
    this.setState({ pageNum: page }, () => {
      this.getOffers();;
      window.scrollTo({top: 0});    
    });
  }; 


  paramsRepricing(enableRepriceAdd){

    switch (enableRepriceAdd){
      case true:
        return {axios: {repriceAdded: true}, message:{success: 'Offer added to Repricing', failed: 'Failed adding offer to Repricing' }}
      case false: 
        return {axios: {repriceAdded: false, repricingEnabled: false}, message:{success: 'Offer removed from Repricing', failed: 'Failed removing offer from Repricing' } } 
        default:
          return null
      }
  }

  async toogleRepricingAdded(data) {

    let params = this.paramsRepricing(data.enableRepriceAdd)

    try {
      const response = await axios.patch(`${config.api.BASE_URL}/offers/${data.offer}`,params.axios, {
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
      });
      
       this.createNotification(params.message.success, 'success')   
        this.handleOffersChange(response.data)
    } catch (error) {
      this.createNotification(error.response.data.toString(), 'danger')
    }

  }

  handleOffersChange = (offer) => {
    // // 1. Make a shallow copy of the items
     let items = [...this.state.offers];
    // // 2. Make a shallow copy of the item you want to mutate
     let index = items.findIndex(e => e.id === offer.id);
     
    items[index] = offer
    // // 3. Replace the property you're intested in
    // item.name = 'newName';
    // // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    // items[1] = item;
    // // 5. Set the state to our new copy
    this.setState({offers: items});
    
  }


  async updateOffer(params) {
    
    try {
      const response = await axios.patch(`${config.api.BASE_URL}/offers/${params.id}`,{
        min_price: params.minPrice, 
        max_price: params.maxPrice,
        repricing_enabled: params.repricingEnabled
     
      }, {
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
      });   
      this.handleOffersChange(response.data)
    } catch (error) {
      console.error(error);
    }

  }


  createNotification(text, type){
    let message = {
      message: text,
      type: type,                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 1000 
      }} 
    store.addNotification(message)
  }


  copyToClipboard(text){
    navigator.clipboard.writeText(text)

    let message = {
      message: 'Copied to Clipboard',
      type: 'success',                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 1000 
      }} 
    store.addNotification(message)

  }



bulkUpdate = (props) => {
    return axios.post(`${config.api.BASE_URL}/offers/batch_update`, {ids: this.state.selectedOffers, updates: props.updates}, {
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
    })
        .then(res => {
                window.analytics.track("Bulk update", {
                    offers: this.state.selectedOffers.length
                })
                this.setState({selectedOffers: []})
                res.data.successful.map((offer) => {
                    this.handleOffersChange(offer)
                })
                return res
            }
        )

}


handleSearchSubmit = () => {
  this.setState({ pageNum: 0 }, () => {
    this.getOffers()     
  });
}


change(data){

  this.setState({pageNum: 0, selectedStatus: data, status: data.map((k) => k.label)}, this.getOffers)

}


handleSearchChange = (value) => {
  this.setState({search: value});
}


checkboxHandle = (result, type) => {
  this.setState({[(type)]: result, pageNum: 0}, this.getOffers)
} 

  renderFilters(){
    
  
    const options = [
      { value: 'Buyable', label: 'Buyable'},
      { value: 'Not Buyable', label: 'Not Buyable' },
      { value: 'Disabled by Seller', label: 'Disabled by Seller' },
      { value: 'Disabled by Takealot', label: 'Disabled by Takealot' }
    ]

    const optionsBuyBox = [
      { value: 'true', label: 'Winning'},
        { value: 'false', label: 'Losing' }
      ]
    const optionsRepricing = [
      { value: 'true', label: 'Yes'},
      { value: 'false', label: 'No' }
    ]

      //const [selected, setSelected] = useState([]);
      return(  

        <>
          <Stack direction="horizontal" gap={3} className='mb-2'>
              <Search 
              submitCallback={this.handleSearchSubmit}
              updateCallback={this.handleSearchChange}
              />
              <div className="vr" />
            <FilterSelector>
              <ChoiceList 
                title='Status'
                selected={this.state.status}
                onChange={(e) => {this.checkboxHandle(e, 'status')}}
                options={options}
                allowMultiple
              />
              <ChoiceList 
                title='Buy Box'
                selected={this.state.buyboxStatus}
                onChange={(e) => {this.checkboxHandle(e, 'buyboxStatus')}}
                options={optionsBuyBox}
              />
              <ChoiceList 
                title='Added to Repricing'
                selected={this.state.repriceAdded}
                onChange={(e) => {this.checkboxHandle(e, 'repriceAdded')}}
                options={optionsRepricing}
                
              />
      
            </FilterSelector>
          </Stack>

        </>)}


    renderRows(){
      
      return(
       
        <div>
          {this.state.isSearching ?
          <Spinner className="spinner-table" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner> : ""
    }
    
        { (this.state.offers).map(
          (offer, i) => 
    
          <ProductTitleBlock 
            key={i}
            offer={offer}
            repricing 
            repricingCallback={this.toogleRepricingAdded.bind(this)}
            isSearching={this.state.isSearching}
            isSuperAdmin={this.props.sellerAccount.data.superAdmin}
            >
                <Col lg={3} xs={2}>
              <Row className="align-items-center">             
                  <Col lg={3}>
                    {offer.sellerName != 'Takealot'? <a href={"https://www.takealot.com/seller/?sellers="+ offer.sellerId} target='_blank'>{offer.sellerName}</a> : offer.sellerName} 
                   </Col>
                  <Col lg={2}>{offer.sellerCount}</Col>
                  <Col lg={2}><NumberFormat value={offer.buyBoxPrice} displayType={'text'} thousandSeparator={true} prefix={'R'}></NumberFormat></Col>
              </Row>
            </Col>

            <Col lg={2} xs={2}>
              <Row>   
                  <Col lg={3}><NumberFormat value={offer.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'R'}></NumberFormat> </Col>
                  <Col lg={3}><SaleStatusBadge>{offer.status}</SaleStatusBadge></Col>
              </Row>
            </Col>

            <Col lg={2} xs={12}>
              <Row >
                  <Col lg={12} align="center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                            {offer.numReviews} customer ratings 
                        </Tooltip>  } >
                     
                         <span style={{borderBottom: "2px dotted #c4cdd5"}}>{offer.starRating}</span>
                     
                    </OverlayTrigger>
                  </Col>
                  <Col lg={12} xs={12}align="center">  {offer.starRating > 0 && (
                        <StarRatingComponent 
                          name="rate2" 
                          editing={false}
                          starCount={5}
                          value={offer.starRating} /> ) }   
                  </Col>
                </Row>
              </Col>      
    </ProductTitleBlock>

          
          )
        }
      </div>)}

humanizeDate(date){
  if (dateFns.isToday(date)){
   return dateFns.format(date,'h:mma')
  } else {
    return dateFns.format(date,'MMM D, h:mma')
  }

}

updateOfferPayload = ({offerId, payload}) =>{

    return axios.patch(`${config.api.BASE_URL}/offers/${offerId}`, {...payload}
        , {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
        }).then((response) => {
        this.handleOffersChange(response.data);
        return response.data} )
}


renderOffers(){
  return(


      <OfferDesktopTable
          isSearching={this.state.isSearching}
          offers={this.state.offers}
          selectedOffers={this.state.selectedOffers}
          updateOffer={this.updateOfferPayload}
          offerSelect={this.handleOfferSelect}
          selectAllOffers={this.handleSelectAllOffers}
          bulkUpdate={this.bulkUpdate}

      />


          // removeFromRepricer={this.removeFromRepricer}
          // updateOffer={this.updateOfferPayload}
          // offerSelect={this.handleOfferSelect}
          // selectedOffers={this.state.selectedOffers}
          // selectAllOffers={this.handleSelectAllOffers}
          // bulkRemoveFromRepricer={this.bulkRemoveFromRepricer}

    // <div>
    //   <Row className='orders-table-header align-middle'>
    //
    //     <div className="fixedWidthImage"></div>
    //     <Col lg={4} xs={6}>
    //       <Row className="align-items-center " >
    //         <Col lg={12} sm={12}>
    //         <Col>Product</Col>
    //         </Col>
    //       </Row>
    //     </Col>
    //
    //     <Col lg={3} xs={2}>
    //       <Row className="align-items-center">
    //           <Col lg={3}>Winner</Col>
    //           <Col lg={2}>Sellers</Col>
    //           <Col lg={2}>Price</Col>
    //       </Row>
    //     </Col>
    //
    //     <Col lg={2} xs={2}>
    //       <Row>
    //         <Col lg={3}>Your Price </Col>
    //         <Col lg={3}>Status</Col>
    //       </Row>
    //     </Col>
    //
    //     <Col lg={1} xs={12}>
    //       <Row>
    //         <Col className='d-none d-lg-block'>Rating</Col>
    //       </Row>
    //     </Col>
    //
    //   </Row>
    //
    //   {this.renderRows()}
//     <div>
//
// <Row className='mt-3'>
//   <Col>
//
//         </Col>
//         </Row>
//
//        </div>
//
//             </div>
        )
    }

    render() {
        return (
          <div>
          <Helmet>
            <title>Offers</title>
          </Helmet>

          <Stack direction="horizontal" gap={3} >
            <div ><h1>Offers</h1></div>
            <div className="ms-auto"><Button variant="secondary" size="sm"
                  onClick={() => {this.createDataExport()}}>
                   {this.state.buttonLoading?  
                      <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true"/> : "Export to CSV"}
                </Button></div>
          </Stack>


            {!this.state.isLoading && this.renderFilters() }
            {/*{!this.state.isLoading ? this.renderOffers(): <div></div>}*/}

              {!this.state.isLoading ?
                  this.state.offers.length > 0 ? this.renderOffers() : <NoOffersFound /> : <TableLoading />}

            {!this.state.isLoading ? <Paginate page={this.state.pageNum} onPageChange={this.handlePageClick}></Paginate> : "" }
            </div>
        )
    }
}