
import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import config from "../../config";
import auth0Client from "../../Auth/Auth";
import {Table, Badge, Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import dateFns from "date-fns";
import NumberFormat from "react-number-format";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCopy } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle)



const DowngradeToFreeButton = (props) => {
    const [isDowngrading, setIsDowngrading] = useState(false);
    const downgradeToFree = (orgId) => {
        axios.patch(`${config.api.BASE_URL}/admin/organisations/${orgId}/downgrade_to_free`, {}, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`} })
            .then(res => {console.log(res)})
        setIsDowngrading(true)
    }



    return(
        <Button onClick={()=> downgradeToFree(props.org) } variant={'outline-danger'} size="sm" disabled={isDowngrading}>Downgrade to Free</Button>
    )
}



const Organisations = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [organisations, setOrganisations] = useState([]);


    useEffect(() => {

        getOrganisations();


    },[])

    const getOrganisations = () => {
        axios.get(`${config.api.BASE_URL}/admin/organisations`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`} })
            .then(res => {setOrganisations(res.data.organisations);
                setIsLoading(false)})
    }


    const TableRows = () => {
        const planColorHash ={
            'free': 'secondary',
            'starter': 'info',
            'retail': 'success',
            'custom': 'primary'
        }

        const badgeColorHash = {
            'live': 'success',
            'trial': 'info',
            'past_due': 'danger',
            'canceled': 'secondary',
            'unpaid': 'danger'
        }

        const paymentBadgeColorHash = {
            'paystack': 'success',
            'eft': 'secondary',
        }

        const renderLastSeenTime = (lastSeenAt) => {
            const lastSeenDate = new Date(lastSeenAt);
            const now = new Date();
            const diff = dateFns.differenceInMinutes(now, lastSeenDate);
            if (diff < 60) {
                return `${diff} mins ago`;
            } else if (diff < 1440) {
                return `${dateFns.differenceInHours(now, lastSeenDate)} hours ago`;
            } else {
                return `${dateFns.differenceInDays(now, lastSeenDate)} days ago`;
            }
        }


        const renderAccounts = (accounts) => {
              return (
                  accounts.map((account, index) => {
                      return (
                          // <Badge key={index} bg={account[0]? 'success': 'danger'} text={account['isConnected?']? 'light': 'light'} className={'ms-2'}>{account[0]? 'Connected': 'Disconnected'} </Badge>
                          <OverlayTrigger overlay={(<Tooltip id="hi">{account[1]}</Tooltip>)}><span className={'dot me-1'} style={{backgroundColor: account[0]? 'green': 'orange'}} /></OverlayTrigger>
                      )
                  })
              )
        }

        return(
            organisations.map((organisation, index) => {
                return(

                    <tr key={index}>
                        <td>{organisation.companyName}</td>
                        <td>{organisation.billingEmail}</td>
                        <td><Badge bg={planColorHash[organisation.planCode]}>{organisation.planCode}</Badge></td>
                        <td>{organisation.nextBillingAt}</td>
                        <td>{<Badge bg={paymentBadgeColorHash[organisation.paymentMethod]}>{organisation.paymentMethod}</Badge>  }
                            {organisation['hasValidPaymentMethod?'] && organisation.paymentMethod === 'paystack' && <FontAwesomeIcon size='s' icon="check-circle" color={'green'} className={'ms-2'} />}
                        </td>
                        <td><Badge bg={badgeColorHash[organisation.subscriptionStatus]} >{organisation.subscriptionStatus}</Badge> </td>
                        <td>({organisation.sellerAccountsCount}) {renderAccounts(organisation.sellerAccountStatus)}</td>
                        <td> {organisation.repricingEnabledOffersCount}/{organisation.offersInRepricerCount}</td>
                        <td><NumberFormat value={organisation.revenue} displayType={'text'} thousandSeparator={true} prefix={'R'} /></td>
                        <td>{renderLastSeenTime(organisation.lastSeenAt)}</td>
                        <td>{organisation.paidInvoicesCount}/{organisation.invoicesCount}</td>
                        <td><Badge bg={organisation.billingRedirect? 'success': 'light'} text={organisation.billingRedirect? 'light': 'dark'}>{organisation.billingRedirect.toString()} </Badge></td>
                        <td>{organisation.planCode !== 'free' && !organisation['hasValidPaymentMethod?'] && <DowngradeToFreeButton org={organisation.id} />}</td>



                    </tr>
                )
            })
        )
    }


    return (
        <div>
            {!isLoading &&
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Billing Emails</th>
                        <th>Plan</th>
                        <th>Next Billing</th>
                        <th>Payment</th>
                        <th>Status</th>
                        <th>Accounts</th>
                        <th>Repricers</th>
                        <th>Revenue</th>
                        <th>Last Seen</th>
                        <th>Invoices</th>
                        <th>Redirect</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <TableRows/>
                    </tbody>
                </Table>}

        </div>
    );
};

export default Organisations;