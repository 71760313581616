import React, { useState, useEffect } from 'react'
import {Card, Row, Col, Alert, Spinner} from "react-bootstrap"
import config from "../config";
import axios from 'axios';
import auth0Client from "../Auth/Auth"
import BillingInfo from "../components/Billing/BillingInfo";
import PaymentDetails from "../components/Billing/PaymentDetails";
import SubscriptionDetails from "../components/Billing/SubscriptionDetails";
import {userNotification} from '../components/UserNotification'
import {Helmet} from "react-helmet";


const Billing = (props) => {

    const [organisation, setOrganisation] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.analytics.page(`Billing`);
        setIsLoading(true);
        getOrganisation()
    }, []);


    const updateOrganisation = async (organisation) => {
        const response = await axios.patch(`${config.api.BASE_URL}/organisation`, {...organisation},{
            headers: {
                'Authorization': `Bearer ${auth0Client.getAccessToken()}`,'shopdeploy-acc': props.sellerAccount.data.currentAccountId
            }
        });
        setOrganisation(response.data);
    }

    const getOrganisation = async () => {
        try {
            const response = await axios.get(`${config.api.BASE_URL}/organisation`, {
                headers: {
                    'Authorization': `Bearer ${auth0Client.getAccessToken()}`,'shopdeploy-acc': props.sellerAccount.data.currentAccountId
                }
            });
            setOrganisation(response.data);


        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const PaymentRequiredAlert = () => {
        return(
            <Row className="justify-content-md-center mb-2">
                <Col xs lg="8">
            <Alert variant="danger" >
                <Alert.Heading>Payment Method Required</Alert.Heading>
                <p>
                    Please enter your card details to continue using Shopdeploy.
                </p>
            </Alert>
                </Col>
                </Row>
        )
    }

    return(
        <div>
            <Helmet>
                <title>Billing</title>
            </Helmet>
            <div className="mb-3"><h2>Subscription & Billing</h2></div>

            {!isLoading ?
                <>
                {organisation.billingRedirect && <PaymentRequiredAlert />}
                    <SubscriptionDetails organisation={organisation} sellerAccountId={props.sellerAccount.data.currentAccountId} getOrg={getOrganisation}/>

                    <BillingInfo organisation={organisation} callback={updateOrganisation}/>
                    <PaymentDetails organisation={organisation} />
                </>:
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
            }

        </div>
    )
}

export default Billing