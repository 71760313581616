import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { Component } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import MediaQuery from "react-responsive";
import auth0Client from "../Auth/Auth";
import ChoiceList from "../components/ChoiceList";
import FileUpload from "../components/FileUploader";
import FilterSelector from "../components/FilterSelector";
import Paginate from "../components/Paginate";
import DesktopTable from "../components/Repricing/DesktopTable";
import MetricsCard from "../components/Repricing/MetricsCard";
import MobileDisplay from "../components/Repricing/MobileDisplay";
import NoOffersFound from "../components/Repricing/NoOffersFound";
import UsageBar from "../components/Repricing/UsageBar";
import Search from "../components/Search";
import LearnMore from "../components/Shared/LearnMore";
import { Mixpanel } from "../components/Shared/Mixpanel";
import TableLoading from "../components/TableLoading";
import config from "../config";
import "./Offers.css";

library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faEdit);

export default class Repricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSearching: true,
      buttonLoading: false,
      pageNum: 0,
      pageTotal: 0,
      offset: 0,
      offers: [],
      search: null,
      status: [],
      selectedStatus: [],
      setSelected: null,
      buyboxStatus: [],
      priceRangeStatus: [],
      repricingEnabled: [],
      selectedOffers: [],
      quota: { offerRepricerLimit: 0, offersRepricerTracked: 0 },
    };
  }

  async componentDidMount() {
    Mixpanel.track(`Repricing`);
    window.analytics.page(`Repricing`);
    this.setState({ isLoading: true });

    try {
      await this.getOffers();
    } catch (e) {
      alert(e + " Repricing not loaded");
    }
    this.setState({ isLoading: false });
    await this.getQuota();
  }

  async getOffers() {
    this.setState({ isSearching: true });
    let offers = await this.offers();
    this.setState({
      offers: offers.data.offers,
      totalPages: offers.data.pagination.pages,
    });
    this.setState({ isSearching: false });
  }

  async getQuota() {
    let quota = await this.quota();
    this.setState({ quota: quota.data });
  }

  async quota() {
    return axios.get(`${config.api.BASE_URL}/quota`, {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
        "shopdeploy-acc": this.props.sellerAccount.data.currentAccountId,
      },
    });
  }

  async offers(page, perPage, filters) {
    return axios.get(`${config.api.BASE_URL}/repricing`, {
      params: {
        page: this.state.pageNum + 1,
        size: perPage,
        q: this.state.search,
        status: this.state.status,
        winning: this.state.buyboxStatus,
        priceRange: this.state.priceRangeStatus,
        repricingEnabled: this.state.repricingEnabled,
      },
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
        "shopdeploy-acc": this.props.sellerAccount.data.currentAccountId,
      },
    });
  }

  handleSearchSubmit = () => {
    this.setState({ pageNum: 0 }, () => {
      this.getOffers();
    });
  };

  handleSearchChange = (value) => {
    this.setState({ search: value });
  };

  handlePageClick = (page) => {
    this.setState({ pageNum: page }, () => {
      this.getOffers();
    });
  };

  handleOffersChange = (offer) => {
    // // 1. Make a shallow copy of the items
    let items = [...this.state.offers];
    // // 2. Make a shallow copy of the item you want to mutate
    let index = items.findIndex((e) => e.id === offer.id);

    items[index] = offer;
    // // 3. Replace the property you're intested in
    // item.name = 'newName';
    // // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    // items[1] = item;
    // // 5. Set the state to our new copy
    this.setState({ offers: items });
  };

  removeFromList = (offerId) => {
    let items = [...this.state.offers];
    let index = items.findIndex((e) => e.id === offerId);
    items.splice(index, 1);
    this.setState({ offers: items });
  };

  // removeFromRepricer = (offerId) => {
  //     this.setState({isSearching: true})
  //     axios.delete(`${config.api.BASE_URL}/repricing/${offerId}`, {
  //         headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
  //     })
  //     .then(res => {
  //         window.analytics.track("Offer Removed From Repricing", {
  //             offerId,
  //         })
  //         this.setState({isSearching: false})
  //         this.removeFromList(offerId)
  //
  //     })
  //     .catch(err => {
  //         this.setState({isSearching: false})
  //         alert(err)
  //     })
  // }

  bulkRemoveFromRepricerMobile = (offerId) => {
    return axios
      .post(
        `${config.api.BASE_URL}/repricing/batch_remove`,
        { offers: [offerId] },
        {
          headers: {
            Authorization: `Bearer ${auth0Client.getAccessToken()}`,
            "shopdeploy-acc": this.props.sellerAccount.data.currentAccountId,
          },
        }
      )
      .then((res) => {
        window.analytics.track("Offer Removed From Repricing Mobile", {
          offers: this.state.selectedOffers.length,
        });
        this.setState({ selectedOffers: [] });
        this.getQuota();
        res.data.offers.map((offer) => {
          this.removeFromList(offer);
        });
        return res;
      });
  };

  bulkRemoveFromRepricer = () => {
    return axios
      .post(
        `${config.api.BASE_URL}/repricing/batch_remove`,
        { offers: this.state.selectedOffers },
        {
          headers: {
            Authorization: `Bearer ${auth0Client.getAccessToken()}`,
            "shopdeploy-acc": this.props.sellerAccount.data.currentAccountId,
          },
        }
      )
      .then((res) => {
        window.analytics.track("Offer Removed From Repricing", {
          offers: this.state.selectedOffers.length,
        });
        this.setState({ selectedOffers: [] });
        this.getQuota();
        res.data.offers.map((offer) => {
          this.removeFromList(offer);
        });
        return res;
      });
  };

  handleOfferSelect = (offerId) => {
    let selectedOffers = [...this.state.selectedOffers];
    let index = selectedOffers.findIndex((e) => e === offerId);
    if (index === -1) {
      selectedOffers.push(offerId);
    } else {
      selectedOffers.splice(index, 1);
    }
    this.setState({ selectedOffers: selectedOffers });
  };

  handleSelectAllOffers = (event) => {
    if (event.target.checked) {
      let selected = this.state.offers.map((offer) => offer.id);
      this.setState({ selectedOffers: selected });
    } else {
      this.setState({ selectedOffers: [] });
    }
  };

  updateOfferPayload = ({ offerId, payload }) => {
    return axios
      .patch(
        `${config.api.BASE_URL}/offers/${offerId}`,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${auth0Client.getAccessToken()}`,
            "shopdeploy-acc": this.props.sellerAccount.data.currentAccountId,
          },
        }
      )
      .then((response) => {
        this.handleOffersChange(response.data);
        return response.data;
      });
  };

  renderCards() {
    const array = [
      {
        endpoint: "/repricing/metrics",
        item: "active_count",
        label: "Actively Repricing",
      },
      {
        endpoint: "/repricing/metrics",
        item: "min_reached_count",
        label: "Minimum Reached",
      },
      {
        endpoint: "/repricing/metrics",
        item: "max_reached_count",
        label: "Maximum Reached",
      },
      {
        endpoint: "/repricing/metrics/winning",
        item: "winning_count",
        label: "Winning BuyBox",
      },
    ];
    return (
      <Row className="mb-3">
        {array.map((value, index) => {
          return (
            <Col key={index}>
              <MetricsCard {...value} {...this.props} />{" "}
            </Col>
          );
        })}
      </Row>
    );
  }

  checkboxHandle = (result, type) => {
    this.setState({ [type]: result, pageNum: 0 }, this.getOffers);
  };

  renderFilters() {
    const optionsStock = [
      { value: "Buyable", label: "Buyable" },
      { value: "Not Buyable", label: "Not Buyable" },
      { value: "Disabled by Seller", label: "Disabled by Seller" },
      { value: "Disabled by Takealot", label: "Disabled by Takealot" },
    ];

    const optionsRepricing = [
      { value: "true", label: "Enabled" },
      { value: "false", label: "Disabled" },
    ];

    const optionsBuyBox = [
      { value: "true", label: "Winning" },
      { value: "false", label: "Losing" },
    ];

    const priceRange = [
      { value: "min", label: "Minimum Price Reached" },
      { value: "max", label: "Maximum Price Reached" },
      { value: "active", label: "Actively Competing " },
    ];

    //const [selected, setSelected] = useState([]);
    return (
      <>
        <Stack direction="horizontal" gap={3} className="mb-2">
          <Search
            submitCallback={this.handleSearchSubmit}
            updateCallback={this.handleSearchChange}
          />
          <div className="vr" />
          <FilterSelector>
            <ChoiceList
              title="Repricing Status"
              selected={this.state.repricingEnabled}
              onChange={(e) => {
                this.checkboxHandle(e, "repricingEnabled");
              }}
              options={optionsRepricing}
            />

            <ChoiceList
              title="Stock Availability"
              selected={this.state.status}
              onChange={(e) => {
                this.checkboxHandle(e, "status");
              }}
              options={optionsStock}
              allowMultiple
            />

            <ChoiceList
              title="Buy Box"
              selected={this.state.buyboxStatus}
              onChange={(e) => {
                this.checkboxHandle(e, "buyboxStatus");
              }}
              options={optionsBuyBox}
            />
            <ChoiceList
              title="Price Range"
              selected={this.state.priceRangeStatus}
              onChange={(e) => {
                this.checkboxHandle(e, "priceRangeStatus");
              }}
              options={priceRange}
            />
          </FilterSelector>
        </Stack>
      </>
    );
  }

  renderOffers() {
    return (
      <MediaQuery minWidth={1235}>
        {/* You can also use a function (render prop) as a child */}
        {(matches) =>
          matches ? (
            <DesktopTable
              isSearching={this.state.isSearching}
              offers={this.state.offers}
              removeFromRepricer={this.removeFromRepricer}
              updateOffer={this.updateOfferPayload}
              offerSelect={this.handleOfferSelect}
              selectedOffers={this.state.selectedOffers}
              selectAllOffers={this.handleSelectAllOffers}
              bulkRemoveFromRepricer={this.bulkRemoveFromRepricer}
            />
          ) : (
            <MobileDisplay
              isSearching={this.state.isSearching}
              offers={this.state.offers}
              updateOffer={this.updateOfferPayload}
              bulkRemoveFromRepricer={this.bulkRemoveFromRepricerMobile}
            />
          )
        }
      </MediaQuery>
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Repricing</title>
        </Helmet>

        <div className={"d-flex mb-3"}>
          <div>
            <h1>Repricing</h1>
          </div>
          <div className={"ms-auto align-items-center d-flex me-2"}>
            {" "}
            <FileUpload
              currentAccountId={this.props.sellerAccount.data.currentAccountId}
              uploadType={"repricing"}
            />
          </div>
          <div className={"d-none d-sm-block"}>
            {this.props.sellerAccount.data.sellerAccount.organisation && (
              <UsageBar
                total={this.state.quota.offerRepricerLimit}
                used={this.state.quota.offersRepricerTracked}
                isBillingOwner={this.props.sellerAccount.data.billingOwner}
              />
            )}
          </div>
        </div>

        {this.renderCards()}
        {!this.state.isLoading ? this.renderFilters() : ""}

        {!this.state.isLoading ? (
          this.state.offers.length > 0 ? (
            this.renderOffers()
          ) : (
            <NoOffersFound />
          )
        ) : (
          <TableLoading />
        )}

        {!this.state.isLoading ? (
          <Paginate
            page={this.state.pageNum}
            onPageChange={this.handlePageClick}
            totalPages={this.state.totalPages}
          ></Paginate>
        ) : (
          ""
        )}
        <LearnMore
          link={"https://www.shopdeploy.com/docs/repricing/"}
          title={"repricing"}
        />
      </div>
    );
  }
}
