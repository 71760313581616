import React, { Component } from "react";
import {Row, Col, Form, Spinner, Button} from "react-bootstrap"
import config from "../config";
import axios from 'axios';
import auth0Client from "../Auth/Auth"
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export default class Account extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            user: [],
            firstName: "",
            lastName: "",
            phoneNumber: "",
            buttonLoading:false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
        window.analytics.page(`Settings`);
        this.setState({ isLoading: true });
        try {
            await this.getUser();
            
          } catch (e) {
            alert(e + " Error Loading User");
          }
        this.setState({ isLoading: false });
          
    }

    handleChange(event) {
        this.setState({[`${event.target.id}`]: event.target.value});
      }

     

  async handleSubmit(event) {
    
    event.preventDefault();
    this.setState({buttonLoading:true})
    await this.updateUser()
    this.setState({buttonLoading:false})
    
  }   
    
  createNotification(text, type){
    let message = {
      message: text,
      type: type,                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 1000 
      }} 
    store.addNotification(message)
  }


    async updateUser(){
        try {
            const response = await axios.patch(`${config.api.BASE_URL}/users`, {
                firstName: this.state.firstName, 
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber
            }, {
              headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`}
            });
             
             this.setState({ user: response.data });
             this.createNotification('Changes Saved','success');
          } catch (error) {
            
            console.error(error);
          }
    }

    async getUser(){
    let response = await this.user()
      this.setState({ user: response.data });
      this.setState({firstName: response.data.firstName, lastName: response.data.lastName, phoneNumber: response.data.phoneNumber})
    }

    async user(){
        return axios.get(`${config.api.BASE_URL}/users`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`}
          });
    }

    async toogleNotifications(data) {

        
        try {
          const response = await axios.patch(`${config.api.BASE_URL}/users`, {[`${data.id}`]: !this.state.user[`${data.id}`]}, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`}
          });
           this.setState({ user: response.data });
           this.createNotification('Changes Saved', 'success')
        } catch (error) {
          
          console.error(error);
        }
    
      }

    
    renderSettings(){
        // console.log(this.state.user)
        return(<>
        <Row className="justify-content-md-center mb-5">
            <Col xs lg="3">
                <strong>Details</strong>
            </Col>
            <Col md="5">
                <Form onSubmit={this.handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={this.state.firstName} onChange={this.handleChange} id="firstName"/>
                        </Form.Group>

                        <Form.Group as={Col} sm="6" >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={this.state.user.lastName} onChange={this.handleChange} id="lastName"/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder={this.state.user.email} disabled/>
                        </Form.Group>

                        <Form.Group as={Col} sm="6" >
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="phone" value={this.state.user.phoneNumber} onChange={this.handleChange} id="phoneNumber"/>
                        </Form.Group>
                    </Row>
                    {/* <Button variant="primary" type="submit">
                        Save
                    </Button> */}
                    <Button style={{'width':'60px'}} variant="primary" type="submit">
                    {!this.state.buttonLoading? 

                    'Save' : 
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  
                    
                }

                </Button>


                </Form>
            </Col> 
            
        </Row>
        
    <Row className="justify-content-md-center ">
        
        <Col xs lg="3">
            
            <strong>Email Notifications</strong>
            <div><small className="text-muted">Changes to these settings apply to all stores in your account.</small></div>
        </Col>
        <Col md="5">
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} sm="12" controlId="formGridEmail" className="mb-3">
                    <Form.Check 
                        type="switch"
                        id="lostBuyBoxNotifications"
                        label="Lost Buy Box"
                        checked={this.state.user.lostBuyBoxNotifications}
                        onChange={(e) => this.toogleNotifications(e.target)}
                    />
                    <Form.Text id="custom-switch" muted>Receive an email notification when you lose the buy box</Form.Text>
                    </Form.Group>

                    {/*<Form.Group as={Col} sm="12" controlId="formGridEmail" className="mb-3">*/}
                    {/*<Form.Check */}
                    {/*    type="switch"*/}
                    {/*    id="offerRepricedNotifications"*/}
                    {/*    label="Offer Repriced"*/}
                    {/*    checked={this.state.user.offerRepricedNotifications}*/}
                    {/*    onChange={(e) => this.toogleNotifications(e.target)}*/}
                    {/*/>*/}
                    {/*<Form.Text id="custom-switch" muted>Receive an email notification when your offer is automatically repriced</Form.Text>*/}
                    {/*</Form.Group>*/}
{/* 
                    <Form.Group as={Col} sm="12" controlId="formGridEmail" className="mb-3">
                    <Form.Check 
                        type="switch"
                        id="minPriceReachedNotifications"
                        label="Min Price Reached"
                        checked={this.state.user.minPriceReachedNotifications}
                        onChange={(e) => this.toogleNotifications(e.target)}
                    />
                    <Form.Text id="custom-switch" muted>Receive an email notification when your an offer reaches its Minimum Price</Form.Text>
                    </Form.Group> */}
                </Row>
            </Form>
        </Col> 
    </Row>
    
    </>
    )
    }


    render() {
        return (
            <div>
                <div className="mb-3"><h2>Manage User Account</h2></div>
                {!this.state.isLoading ? this.renderSettings(): <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>} 
                

            </div>
        )
    }
}
