import React, { Component } from 'react'
import {Row, Col, CardGroup, Card, Spinner} from 'react-bootstrap';
import Percentage from '../components/Percentage'
import PercentageInverse from '../components/PercentageInverse'
import config from "../config";
import auth0Client from "../Auth/Auth"
import axios from 'axios';


export default class DashboardCards extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isCurrentLoading: true,
          isPreviousLoading: true,
          //isLoading:true
          current:[],
          previous:[]
        
        }
        
      }

    componentDidMount(){
      
        this.setMetrics()
      }
  
    componentDidUpdate(prevProps){
        if (prevProps.previousPeriod !== this.props.previousPeriod || prevProps.currentPeriod !== this.props.currentPeriod)  {
            this.setMetrics()
          }

    }

    async setMetrics(){
        this.setState({isCurrentLoading:true})
        this.setState({isPreviousLoading:true})

        let current = await this.getMetrics(this.props.currentPeriod)
        this.setState({current})
        this.setState({isCurrentLoading:false})

        let previous = await this.getMetrics(this.props.previousPeriod)
        this.setState({previous})
        this.setState({isPreviousLoading:false})


    }


    async getMetrics(date){
        let result = await axios.get(`${config.api.BASE_URL}/metrics`, {
            params: {start: date.start, end:date.end, },
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccountId,
          }
         
         });
       

         return result.data    
    
    }
  


    render() {
        return (

          // <Card className="text-center" >
          // <Card.Title className="center-title">
          //     {!this.state.isCurrentLoading ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.current.revenue)  : 
          // <Spinner animation="border" role="status">
          //   <span className="sr-only">Loading...</span>
          // </Spinner>} </Card.Title>
          // <Card.Text>
          // <small className="text-muted">Sales today so far</small>
          // </Card.Text>
          // </Card>
          <Row className="mt-md-3">
            <Col>
          <CardGroup>
          <Card >
          <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">Revenue</Card.Subtitle>  
          <Card.Title> {!this.state.isCurrentLoading ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.current.revenue)  : 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>}
                    </Card.Title>

                    {(!this.state.isCurrentLoading && !this.state.isPreviousLoading)? <Percentage display={new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.previous.revenue)} startNum={this.state.previous.revenue} endNum={this.state.current.revenue}/>  : 
                    "~"}
          </Card.Body>
          </Card>
          
          <Card >
          <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">Units sold</Card.Subtitle>  
          <Card.Title> {!this.state.isCurrentLoading ? this.state.current.orders  : 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>}
                    </Card.Title>

                    {(!this.state.isCurrentLoading && !this.state.isPreviousLoading)? <Percentage display={this.state.previous.orders} startNum={this.state.previous.orders} endNum={this.state.current.orders}/>  : 
                    "~"}
          </Card.Body>
          </Card>
           <Card >
          <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">Avg order value</Card.Subtitle>  
          <Card.Title> {!this.state.isCurrentLoading ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.current.revenue/this.state.current.orders)  : 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>}
                    </Card.Title>

                    {(!this.state.isCurrentLoading && !this.state.isPreviousLoading)? <Percentage display={new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.previous.revenue/this.state.previous.orders)} startNum={this.state.previous.revenue/this.state.previous.orders} endNum={this.state.current.revenue/this.state.current.orders}/>  : 
                    "~"}
          </Card.Body>
          </Card>
          <Card >
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">Units returned</Card.Subtitle>  
              <Card.Title> {!this.state.isCurrentLoading ? this.state.current.returns : 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>}
                    </Card.Title>

                    {(!this.state.isCurrentLoading && !this.state.isPreviousLoading)? <PercentageInverse display={this.state.previous.returns} startNum={this.state.previous.returns} endNum={this.state.current.returns}/>  : 
                    "~"}
            </Card.Body>
          </Card>
          <Card >
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">Leadtime sales</Card.Subtitle>  
              <Card.Title> {!this.state.isCurrentLoading ? <span> <span>{((this.state.current.leadtime/this.state.current.orders) * 100).toFixed(2) }%</span> <small>({this.state.current.leadtime}<small className="text-muted">/ {this.state.current.orders}</small>)</small></span> : 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>}
                    </Card.Title>

              {(!this.state.isCurrentLoading && !this.state.isPreviousLoading)?  <div> <PercentageInverse display={   <span> {((this.state.previous.leadtime/this.state.previous.orders) * 100).toFixed(2) }% <small>({this.state.previous.leadtime}<small>/{this.state.previous.orders}</small>)</small></span>} startNum={(this.state.previous.leadtime/this.state.previous.orders) } endNum={(this.state.current.leadtime/this.state.current.orders) }/>
                 </div> : 
                    "~"}
            </Card.Body>
          </Card>
        </CardGroup>
        </Col>
        </Row>
            
        

        )
    }
}
