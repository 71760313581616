import React, { Component } from 'react'
import {Row, Col, Card, Spinner} from 'react-bootstrap';
import config from "../config";
import auth0Client from "../Auth/Auth"
import axios from 'axios';

export default class DailyMetrics extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true,
        
        }
        
      }

   async componentDidMount(){
      let daily = await this.getDailyMetrics()
      this.setState({daily})
      this.setState({isLoading:false})
    }


    getDailyMetrics(){
        return axios.get(`${config.api.BASE_URL}/metrics/daily`, { 
          headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount,
        }
        });
       }


    renderDailyMobileMetrics(){
    
        return(
         <Row>
            <Col>
              <Card className="text-center" >
            <Card.Title className="center-title">
                {!this.state.isLoading ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.daily.data.todaySales)  : 
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>} </Card.Title>
            <Card.Text>
            <small className="text-muted">Sales today so far</small>
            </Card.Text>
            </Card>
            </Col>
            <Col> 
              <Card className="text-center"><Card.Title className="center-title">    {!this.state.isLoading ? this.state.daily.data.todayUnits : 
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>} </Card.Title>
            <Card.Text>
            <small className="text-muted">Units today so far</small>
            </Card.Text></Card>
            </Col>
            {/* <Col> 
              <Card className="text-center">3 of 3</Card>
            </Col>  */}
          </Row>
         
        
        )
      }



    render() {
        return (
            <>
                {this.renderDailyMobileMetrics()}
           </>
        )
    }
}
