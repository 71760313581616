import React, {useEffect, useState} from 'react';
import config from "../../config";
import axios from 'axios';
import auth0Client from "../../Auth/Auth"
import {Button, Modal, Card, Col, Row, Container, CardGroup, Spinner} from "react-bootstrap";
import NumberFormat from 'react-number-format';
import Pluralize from 'react-pluralize'
import {userNotification} from "../UserNotification";
import { trackButtonClicked, customEvent } from "../../segment/analytics";




const ChangePlan = (props) => {
    const [plans, setPlans] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showChangePlan, setChangePlan] = useState(false);
    const [newPlan, setNewPlan] = useState(null);
    const [detailsViewed, setDetailsViewed] = useState(false);
    const [fetchingNewPlan, setfetchingNewPlan] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const handleClose = () => setChangePlan(false);
    const handleShow = () => {setChangePlan(true);
        trackButtonClicked('Change Plan');
        setIsLoading(true);
        getPlans()
    }



    useEffect(() => {
      if (selectedPlan !== null) {
          computePlanChange()
      }
    }, [selectedPlan]);

    const getPlans = async () => {
        try {
            const response = await axios.get(`${config.api.BASE_URL}/plans`, {
                headers: {
                    'Authorization': `Bearer ${auth0Client.getAccessToken()}`
                }
            });

            setPlans(response.data.plans);
             setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const updateSubscriptionPlan = async () => {
        setIsUpdating(true)
        try {
            const response = await axios.patch(`${config.api.BASE_URL}/subscription`, {
                planCode: selectedPlan
            },{
                headers: {
                    'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.sellerAccountId
                }
            });

            handleClose()
            props.getOrg()
            userNotification('Plan successfully updated', 'success')
            customEvent('Plan successfully updated', {
                planCode: selectedPlan
            });
            // setPlans(response.data.plans);
            // setIsLoading(false);
        } catch (error) {
            console.error(error);
            alert("Something went wrong when trying to change your plan. Please contact support@shopdeploy.com.")
        }
        setIsUpdating(false)
    }

   const computePlanChange = async () => {
       try {
           setDetailsViewed(false)
           setfetchingNewPlan(true)
           const response = await axios.post(`${config.api.BASE_URL}/subscription/compute`, {
               planCode: selectedPlan
           }, {
               headers: {
                   'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.sellerAccountId
               }
           });

           setNewPlan(response.data);
           setfetchingNewPlan(false)
           setDetailsViewed(true)
           customEvent('Computing Plan Change', {
               planCode: selectedPlan
           });
       } catch (error) {
           console.error(error);
       }
   } 
    
    
   const renderSelectButtons = (planCode) => {
        if (planCode === props.organisation.plan.code) {
            return <Button variant="primary" disabled>Current</Button> } 
        else if (planCode === selectedPlan) {
            return <Button variant="primary">Selected</Button>}
        else {
            return <Button variant="primary" id={planCode} onClick={(e) => {setSelectedPlan(e.target.id)}} >Choose Plan</Button>
            
        }
            
   }
    
   
   const renderPlanChangeDetail = () => {

        if (newPlan !== null && !fetchingNewPlan) {
            return (
                <Card>
                    <Card.Body>
                    <Card.Title>Updated Plan</Card.Title>
                        <p><small>Total Due Today: < NumberFormat value={newPlan.initialChargeAmount} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2} fixedDecimalScale /></small></p>
                    <small className={'text-muted'}> < NumberFormat value={newPlan.recurringChargeTotal} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2} fixedDecimalScale /> will be billed every month until canceled</small>

                 </Card.Body>
                </Card>
            )
        } else if (fetchingNewPlan) {
            return <div>Calculating new plan pricing...</div>
        }
   }
    
    const renderPlans = () => {
        return plans.map((plan, index) => {
            return(
                <Col >
                    <Card  border={`${plan.code === selectedPlan ? 'primary': ''}`} key={index} className="mb-3" bg={'light'}>
                        <Card.Body>
                            <Card.Title>{plan.name}</Card.Title>
                            <Card.Text>
                                <div >
                                    <h3 className='d-inline-block'><NumberFormat value={plan.recurringPrice} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={0} />  </h3><small className="text-muted"> ex VAT</small>

                                </div>
                                <ul style={{'list-style': 'none', 'padding-inline-start': 0}}>
                                    <li><small> {plan.repricerLimit} Automated Repricers</small></li>
                                    <hr></hr>
                                    <li><small><Pluralize singular={'user'} count={plan.userLimit} /></small></li>
                                    <li><small><Pluralize singular={'Seller Account'} count={plan.sellerAccountLimit} /></small></li>
                                    <li></li>
                                </ul>


                            </Card.Text>
                            {/*<Button variant="primary">Select</Button>*/}
                            {renderSelectButtons(plan.code)}
                        </Card.Body>
                    </Card>
                </Col>
            )
        })
    }
    

    return (
        <>
            <Button className="mt-2" variant={"outline-primary"} onClick={handleShow}>Change Plan</Button>
            <Modal show={showChangePlan} backdrop="static" onHide={handleClose} dialogClassName={'modal-85w'} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Change Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12} lg={9}>
                                <Row xs={1} md={2} lg={4} className="g-2">{!isLoading ? renderPlans() : <div>Loading...</div>} </Row>
                            </Col>
                            <Col md={6} lg={3} l>
                                <Card className="mb-2">
                                    <Card.Body>
                                        <Card.Title>Summary</Card.Title>
                                        <p><small>Your current plan:<strong> {props.organisation.plan.name}</strong></small></p>
                                        <small>Currently paying: <strong><NumberFormat value={props.organisation.nextBillingAmount} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2} fixedDecimalScale />/mo</strong></small>
                                    </Card.Body>
                                </Card>
                                {renderPlanChangeDetail()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={!detailsViewed} onClick={updateSubscriptionPlan}>

                        {isUpdating ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : 'Change Plan'}




                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ChangePlan;