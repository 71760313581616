import React, { useState, useEffect } from 'react';
import config from "../../config";
import axios from 'axios';
import auth0Client from "../../Auth/Auth";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCopy } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Spinner, InputGroup, FormControl, Badge, Form, Button} from "react-bootstrap";
import {userNotification, copyToClipboard} from '../UserNotification';
import { differenceInHours, differenceInMinutes } from 'date-fns'
library.add(faCopy)


const WebhookBadge = (props) => {
  
  if(differenceInHours(new Date(), props.triggeredAt) < 24) {
      return <Badge bg="success">Active</Badge>
  }
  else{
      return <Badge bg="secondary">Inactive</Badge>
  }
}

function Webhook(props) {

    const [secret, setSecret] = useState(props.webhook.secret || '');
    const [editLocked, seteditLocked] = useState(true);
    const [ButtonLoading, setButtonLoading] = useState(false);

    const handleSecretSubmit = (event) => {
        event.preventDefault();
        setButtonLoading(true)
        updateSecret()
    }

    const updateSecret = async () => {
        try {
            await axios.patch(`${config.api.BASE_URL}/webhook`, {
                secret: secret, 
            }, {
              headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': props.currentAccountId}
            }).then((res) => {
              
              setSecret(res.data.secret);
              setButtonLoading(false);
              seteditLocked(true);
              userNotification('Webhook secret successfully updated', 'success');
            })
           
             
          } catch (error) {
            setButtonLoading(false);
            userNotification('Error updating Webhook secret', 'danger');
            
          }
    }

  return (<>
  <Row className="justify-content-md-center mb-5">
    <Col xs lg="3">
        
        <strong>Webhook </strong> <WebhookBadge triggeredAt={props.webhook.triggeredAt} />
        <div><small className="text-muted">Receive near real-time updates for orders and offers when you setup a webhook.</small></div>
        <div className='mt-1'><small className="text-muted">Supported events:<ul><li>Sale Status Changed</li><li>Offer Updated</li><li>Offer Created</li></ul></small></div>
    </Col>
  
    <Col md="5">
    
      <Form.Label>Webhook URL</Form.Label>
      <InputGroup className="mb-3">
      <FormControl readOnly
        value={props.webhook.url}/>
      <Button onClick={() => copyToClipboard(props.webhook.url) } variant="outline-secondary" id="button-addon2"><FontAwesomeIcon size='1x' icon="copy" /> Copy URL</Button>
      </InputGroup>

      <Form onSubmit={handleSecretSubmit}>
      <Form.Label>Secret</Form.Label>
            <Row className="mb-3">
                <Form.Group as={Col} >
                    <Form.Control type="text" value={secret } placeholder='Paste in Takealot Webhook Secret' onChange={(event)=> {setSecret(event.target.value)}} id="secret" disabled={editLocked}/>
                </Form.Group>
            </Row>
            
            <Button className="me-2" style={{'width':'120px'}} variant="outline-secondary" onClick={()=> {seteditLocked((prevEditLocked => !prevEditLocked ))}}>
                {editLocked? 'Edit Secret' : 'Cancel' }
            </Button>

            {!editLocked &&
            <Button className="ml-2" variant="primary" type='submit' style={{'minWidth': '61px'}} disabled={!secret}>
                {ButtonLoading ? <Spinner
                  as="span"
                  animation="border" 
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  /> : "Save" } 
            </Button>}
        </Form>
    </Col> 
  </Row>
  </>)

}

export default Webhook
