import React, { Component } from 'react'
import ListOrdersTable from '../components/ListOrdersTable'
import config from "../config";
import axios from 'axios';
import auth0Client from "../Auth/Auth"
import {Row,Col,Card} from 'react-bootstrap'
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import PeriodSelector from '../components/PeriodSelector'
import {Helmet} from "react-helmet";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';
import moment from 'moment'


export default class OffersShow extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isOfferLoading: true,
            orders: [],
            offer: [],
            graph: []
          };
      }
  
    async componentDidMount(){
        window.analytics.page(`Offers Show`);

        this.offerDetails({})
        
        try {
        let orders = await this.getOrders()
        this.setState({orders: orders.data});
         } catch (e) {
          alert(e + "Cannot fetch Order Data");
         }

  }


  async offerDetails(params){
    this.setState({ isOfferLoading: true });
    try {
      let offer = await this.getOfferDetails(params)
      this.setState({offer: offer.data.details, graph:offer.data.graph});
       } catch (e) {
        alert(e + "Cannot fetch Offer Details");
      }
   this.setState({ isOfferLoading: false });
  }

  getOfferDetails(params){
    console.log(params)
    return axios.get(`${config.api.BASE_URL}/offers/${this.props.match.params.id}`, {
      params:params,
        headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
      });
  }
    getOrders() {
        return axios.get(`${config.api.BASE_URL}/offers/${this.props.match.params.id}/orders`, {
          headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
        });
}




    renderOfferData(){
        return (<h3>{this.state.offer.title}</h3>)
    }

    renderGraph(){
        const options = {
            title: {
              text: null
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    day: '%e of %b'
                }, 
                crosshair: true
             }],
            series: [{
                name: 'Units',
                type: 'column',  
                data: this.state.graph.units.data,
                pointStart: Date.parse(this.state.graph.units.start),
                pointInterval: 24 * 3600 * 1000,
                           
              } ]
          }
        return(
            <>
            <Row>
            <Col>   <Card >
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
          </Card></Col>
            <Col>2 of 2</Col>
          </Row>
          <Row>
            <Col>1 of 3</Col>
            <Col>2 of 3</Col>
            <Col>3 of 3</Col>
          </Row>
          </>
        )
    }

    render() {
        console.log('offers show')
        console.log(this.state)

 
        return (
            
          
          <main role="main" className="col-sm-12 main pt-3">
            
                   <PeriodSelector  startDate={moment().subtract(29, 'days').format('YYYY-MM-DD')} endDate={moment().format('YYYY-MM-DD')} eventHandler={this.offerDetails.bind(this)} />

                   {/* startDate={moment().subtract(29, 'days').format('YYYY-MM-DD')} endDate={moment().format('YYYY-MM-DD')} */}
                  {!this.state.isOfferLoading ? this.renderOfferData(): <div>Loading...</div>} 
                   {!this.state.isOfferLoading ? this.renderGraph(): <div>Loading...</div>} 

                          

                <ListOrdersTable tableData={"this.state.orders.data"}></ListOrdersTable> 

           
                
                
            </main>
        )
    }
}
