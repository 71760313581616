import React, { Component } from 'react'
import auth0Client from "../Auth/Auth"
import config from "../config";
import axios from 'axios';
import { Col } from 'react-bootstrap';



export default class OrderTransactions extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true,
          data: []
          
         
        };
    
       
    }

    getTransactionData() {
        return axios.get(`${config.api.BASE_URL}/transactions/${this.props.children}`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getAccessToken()}`, 'shopdeploy-acc': this.props.props.currentAccountId}
          });
          
    }

    async componentDidMount() {
        
        try {
            const data = await this.getTransactionData();
            
            this.setState({ data });
            
          } catch (e) {
            alert(e + " error getting transaction data");
          }
          this.setState({ isLoading: false });

    }

    
    transactionLineItems(transactions){
        return(<ul className="transaction-items">
            {transactions.map((item, i) =>
            <li key={i}>{item.description} : {  new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(item.inc_vat)}</li>)}
            </ul>
        )

    }


    renderChart(data) {
       
        return( !data.length ? (<i>transaction data not yet available for this order</i>) : this.transactionLineItems(data))
      
            
   }



    render() {
        return (<>
    <div className="fixedWidthBox"> </div> 
            
               <Col className="pt-3">
               {!this.state.isLoading ? this.renderChart(this.state.data.data) : "fetching transactions..."}
               </Col>   

            </>
        )
    }
}
